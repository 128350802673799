import classes from "./Language.module.css";

export function Language() {
  return (
    <div className={classes.offerContainer}>
      <div className={classes.titleContainer}>
        <div className={classes.offerText}>
          Odkryj fascynujący świat języków z naszymi różnorodnymi kursami, w tym
          intensywnymi kursami języka polskiego, angielskiego, niemieckiego i
          rosyjskiego. Specjalnie zaprojektowane kursy obejmują także
          doskonalenie umiejętności językowych dla przewodników po Krakowie.
        </div>
      </div>

      <section className={classes.languageOffer}>
        <article className={classes.LANG}>
          <div className={classes.marqueeText}>
            🇵🇱POLISH FOR FOREIGNERS - preparing for the respective certificate
            of proficiency in the Polish language as a foreign language
          </div>
          <div className={classes.courseDescription}>
            Semestralny kurs języka polskiego dla obcokrajowców (Polish for
            foreigners) dla początkujących oraz na różnych poziomach
            zaawansowania. Każdy z tych kursów kończy się możliwością
            przystąpienia do egzaminu na certyfikat znajomości języka polskiego
            jako obcego na odpowiednim poziomie. Istnieje też możliwość
            wykupienia:
            <br />
            a/ pakietu grupowego przy założeniu pobierania nauki grupowej w
            małych grupach (3-6 osób) i w wymiarze np. 24 spotkania po 90 min.,
            wówczas opłata ze pakiet wynosi 1050 zł od osoby,
            <br />
            b/ pakietu indywidualnego (tylko dla 1 osoby) w 2 wariantach:
            wariant 1: 10 spotkań po 60 min. - cena za pakiet: 1050zł lub warant
            2: 10 spotkań po 90 min. - cena za pakiet: 1400 zł.
          </div>
        </article>
        <article className={classes.LANG}>
          <div className={classes.marqueeText}>
            🇬🇧JĘZYK ANGIELSKI - przeżyj przygodę z językiem angielskim na
            różnych poziomach naszych kursów!
          </div>
          <div className={classes.courseDescription}>
            Różne poziomy zaawansowania:
            <br />
            a/ kurs semestralny indywidualny (dla 1 osoby): zajęcia 1 raz w
            tygodniu, w sobotę po 90 min. - cena za kurs:770 zł,
            <br />
            b/ kurs semestralny indywidualny (dla 1 osoby): zajęcia 2 razy w
            tygodniu po 90 min. (w poniedziałek i czwartek lub wtorek i piątek)
            - cena za kurs: 1320 zł,
            <br />
            c/ kurs semestralny grupowy (w małych grupach: 3-6 osób) zajęcia 1
            raz w tygodniu (w sobotę) po 90 min. - cena za kurs od 1 osoby: 550
            zł,
            <br />
            d/ kurs semestralny grupowy (w małych grupach 3-6 osób) zajęcia 2
            razy w tygodniu (w poniedziałek i czwartek lub wtorek i piątek) po
            90 min. - cena za kurs od 1 osoby: 1100 zł.
            <br />
            Zajęcia na wszystkich w/w kursach semestralnych trwają od 25 lutego
            do 24 czerwca 2024 roku.
          </div>
        </article>
        <article className={classes.LANG}>
          <div className={classes.marqueeText}>
            🇩🇪JĘZYK NIEMIECKI - intensywny kurs języka niemieckiego, doskonały
            zwłaszcza dla tych, którzy planują podjąć pracę w Niemczech lub
            przygotowują się do matury!
          </div>

          <div className={classes.courseDescription}>
            Są to kursy indywidualne obejmujące różną ilość godzin lekcyjnych w
            zależności od czasu przed wyjazdem i częstości spotkań w tygodniu,
            cena za 1 godz. lekcyjną (60 min): ok. 30 zł. Istnieje też możliwość
            skorzystania z indywidualnego kursu przygotowującego do matury z
            języka niemieckiego. Są to kursy indywidualnie zróżnicowane przede
            wszystkim pod względem i ilości godzin lekcyjnych i częstości ich
            odbywania, cena za 1 godz. lekcyjną (60 min): 30 zł.
          </div>
        </article>
        <article className={classes.LANG}>
          <div className={classes.marqueeText}>
            🇷🇺JĘZYK ROSYJSKI - intensywny kurs języka rosyjskiego, doskonały
            zwłaszcza dla tych którzy przygotowują się do matury z tego języka
          </div>

          <div className={classes.courseDescription}>
            Są to kursy indywidualne obejmujące różną ilość godzin lekcyjnych w
            zależności od czasu trwania kursu i częstości spotkań w tygodniu,
            cena za 1 godz. lekcyjną (60 min): ok. 30 zł. Istnieje też możliwość
            skorzystania z indywidualnego kursu przygotowującego do matury z
            języka rosyjskiego. Są to kursy indywidualnie zróżnicowane przede
            wszystkim pod względem i ilości godzin lekcyjnych i częstości ich
            odbywania, cena za 1 godz. lekcyjną (60 min): 30 zł.
          </div>
        </article>
        <article className={classes.LANG}>
          <div className={classes.marqueeText}>
            🇨🇿🇪🇸🇮🇹🇫🇷KURS DOSKONALENIA JĘZYKOWEGO - dla przewodników po Krakowie,
            przygotowujących się do oprowadzania turystów zagranicznych w języku
            angielskim, czeskim, francuskim, hiszpańskim, niemieckim, rosyjskim
            i włoskim!
          </div>

          <div className={classes.courseDescription}>
            Specjalistyczne kursy najważniejszych języków obcych (angielski,
            czeski, francuski, hiszpański, niemiecki, włoski) przeznaczone dla
            zawodowych przewodników po Krakowie i przygotowujące ich także do
            oprowadzania turystów zagranicznych po naszym mieście w w/w językach
            obcych, znających co prawda któryś z wymienionych powyżej języków
            obcych z nauki szkolnej czy lektoratu uniwersyteckiego, ale nie na
            tyle, żeby swobodnie się nim posługiwać i w związku z tym zachodzi
            konieczność wcześniejszego przygotowania własnych indywidualnych
            (prawie każdy przewodnik ma swój własny indywidualny styl
            oprowadzania) kilku najważniejszych tras miejskich po Krakowie,
            najlepiej z fachową pomocą doświadczonego dzięki długoleniej
            praktyce w oprowadzaniu turystów w danym języku krakowskiego
            przewodnika, a i jak to się często zdarza zarazem wykształconego
            uniwersytecko specjaliste neofilologa w danym języku i dlatego
            przygotowyjący się dopiero do oprowadzania w danym języku jeszcze
            niedoświadczony przewodnik-nowicjusz bardzo chętnie skorzysta i z
            wiedzy merytorycznej i językowej swego bardziej doświadczonego
            zawodowo i językowo kolegi po fachu. Ponieważ ze względu na
            specyfikę takiego kursu, można go rozpocząć w dowolnym czasie i też
            w zasadzie w dowolnym czasie zakończyć, gdy tylko dojdzie się do
            przekonania, że poszczególne trasy miejskie zostały już należycie i
            opracowane i opanowane zadowalająco przez zainteresowanego
            przewodnika, co zawsze następuje po różnej w każdym konkretnym
            przypadku ilości godzin nauki, to i w przypadku tego kursu można
            określić jedynie cenę za 1 godz. lekcyjną (60 min.): 30 zł.
          </div>
        </article>
      </section>
    </div>
  );
}
